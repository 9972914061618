import { switchAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(switchAnatomy.keys);

const baseStyle = definePartsStyle({
  track: {
    bg: '#dedfdf',
    _checked: {
      bg: '#5ba7b8',
    },
  },
});

const switchConfig = defineMultiStyleConfig({ baseStyle });

export default switchConfig;
