import { FOOTER_HEIGHT } from '@/constants';
import { Box, HStack, Image, Link, Text } from '@chakra-ui/react';

type LinkProps = {
  text: string;
  url: string;
};

const FooterLink = ({ text, url }: LinkProps) => (
  <Link
    variant="text"
    href={url}
    fontWeight="bold"
    fontSize="12px"
    color="theme.text.secondary"
    target="_blank"
  >
    {text}
  </Link>
);

export default function App() {
  return (
    <Box
      h={`${FOOTER_HEIGHT}px`}
      w="100%"
      display="flex"
      justifyContent="space-between"
      py="8px"
      px="32px"
      bg="#eceded"
    >
      <HStack gap="16px">
        <Image
          src="/img/logo.svg"
          alt="LOOPASS"
          height={0}
          width={0}
          style={{ width: '75px', height: 'auto' }}
        />
        <Text
          color="theme.text.secondary"
          fontSize="12px"
          fontWeight={400}
          cursor="default"
        >
          © Milogos, Inc. All Rights Reserved.
        </Text>
      </HStack>
      <HStack gap="23px">
        <FooterLink text="運営会社" url="https://www.milogos.co.jp/" />
        <FooterLink text="利用規約" url="https://example.com" />
        <FooterLink
          text="プライバシーポリシー"
          url="https://www.milogos.co.jp/privacy?_gl=1*1rzdsbt*_ga*MTE0MzM5NTA0NC4xNjc0NDUzMTYw*_ga_YCEF93ZBNM*MTcxNTA2MTMwMS4zMi4xLjE3MTUwNjE1NjIuMC4wLjA."
        />
      </HStack>
    </Box>
  );
}
