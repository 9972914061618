import { Box } from '@chakra-ui/react';

interface Props {
  name: string;
  isSelected: boolean;
  onClick: () => void;
}

export default function ItemEnvironmentName({
  name,
  isSelected,
  onClick,
}: Props) {
  return (
    <Box
      as="p"
      py={1}
      pl={8}
      pr={2}
      minHeight="19.44px"
      fontWeight="medium"
      fontSize="12.15px"
      lineHeight="19.44px"
      cursor="pointer"
      borderRadius="4px"
      _hover={{
        bg: 'theme.sidebar.hover',
      }}
      onClick={onClick}
      bg={isSelected ? 'theme.sidebar.selected' : 'theme.sidebar.background'}
      color="white"
      data-testid="sidebar-item-environment-name"
    >
      {name}
    </Box>
  );
}
