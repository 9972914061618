'use client';

import type { Property as CSSProperty } from 'csstype';
import { useId } from 'react';
import { Control, Controller, RefCallBack } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
} from '@chakra-ui/react';

import ReactSelect, { Props as ReactSelectProps } from 'react-select';

interface Option {
  value: string;
  label: string;
  isDisabled?: boolean;
}

interface BaseProps extends ReactSelectProps {
  inputRef?: RefCallBack;
  label: string;
  helperText?: string;
  errorMessage?: string;
  isError?: boolean;
  isRequired?: boolean;
  options: Array<Option>;
}

interface Props extends BaseProps {
  control: Control<any>;
  name: string;
}

const BG_COLOR_SELECTED = '#ddf2f4';
const BG_COLOR_HOVER = '#f4fbfc';

const TEXT_COLOR_DEFAULT = '#232525';
const TEXT_COLOR_DISABLED = '#ccc';

export const UncontrolledSelect = (props: BaseProps) => {
  const {
    label,
    placeholder,
    helperText,
    errorMessage,
    isError,
    isRequired,
    isDisabled,
    ...otherProps
  } = props;
  return (
    <FormControl
      isInvalid={isError}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      <FormLabel fontSize="md">{label}</FormLabel>

      <ReactSelect
        instanceId={useId()}
        className="basic-single"
        classNamePrefix="select"
        name="color"
        required={isRequired}
        isSearchable={false}
        isDisabled={isDisabled}
        placeholder={placeholder || '以下の選択肢からお選びください。'}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, { isFocused }) => ({
            ...base,
            borderColor: '#eceded',
            boxShadow: isFocused ? '0 0 0 1px #3182ce' : undefined,
            _hover: {
              borderColor: '#dedfdf',
            },
          }),
          indicatorsContainer: (base) => ({
            ...base,
            '> div': {
              color: '#232525 !important',
            },
          }),
          dropdownIndicator: (base) => ({
            ...base,
            color: '#232525',
          }),
          placeholder: (base) => ({
            ...base,
            color: '#bebfbf',
            fontSize: '14px',
            lineHeight: '1.6em',
          }),
          option: (
            base,
            { isDisabled: isOptionDisabled, isFocused, isSelected },
          ) => {
            let backgroundColor: CSSProperty.BackgroundColor | undefined;
            let textColor: CSSProperty.Color = TEXT_COLOR_DEFAULT;
            let cursor: CSSProperty.Cursor = 'default';

            if (isOptionDisabled) {
              backgroundColor = undefined;
              textColor = TEXT_COLOR_DISABLED;
              cursor = 'not-allowed';
            } else if (isSelected) {
              backgroundColor = BG_COLOR_SELECTED;
            } else if (isFocused) {
              backgroundColor = BG_COLOR_HOVER;
            }

            return {
              ...base,
              fontSize: '13px',
              lineHeight: '1.4em',
              borderRadius: '4px',
              color: textColor,
              backgroundColor,
              cursor,
            };
          },
          menu: (base) => ({
            ...base,
            padding: '8px 4px',
            marginTop: '2px',
          }),
          input: (base) => ({
            ...base,
            fontSize: '14px',
            lineHeight: '1.6em',
            margin: 0,
            padding: 0,
          }),
          valueContainer: (base) => ({
            ...base,
            padding: '10.5px 12px',
          }),
        }}
        {...otherProps}
      />

      {!isError ? (
        <FormHelperText data-testid="select-helper-text" fontSize="xs" mt={0}>
          {helperText}
        </FormHelperText>
      ) : (
        <FormErrorMessage fontSize="xs" mt={0}>
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
/* eslint-enable */

const Select = (props: Props) => {
  const { control, name, label, options, ...otherProps } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => (
        <UncontrolledSelect
          {...otherProps}
          isRequired
          inputRef={ref}
          options={options}
          label={label}
          value={options.find((c) => c.value === value)}
          onChange={(val: any) => onChange(val.value)}
        />
      )}
    />
  );
};

export default Select;
