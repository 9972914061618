import { AxiosInstance } from 'axios';
import MockAdapter from 'axios-mock-adapter';

import { LPSLOGIN_API_BASE_URL } from '@/constants';

import {
  CreateSettingMockResponse,
  GetSettingMockResponse,
  ListSettingBlankMockResponse,
  ListSettingMockResponse,
} from './values';

interface MockAdapterOptions {
  delayResponse?: number;
  onNoMatch?: 'passthrough' | 'throwException';
}

const mockAdapterConfig: MockAdapterOptions = {
  // To simulate response delays
  delayResponse: 1250,
  onNoMatch: 'passthrough',
};

export const isMockingEnabled = () => {
  const isApiMockingEnabled =
    process.env.NEXT_PUBLIC_API_MOCKING_ENABLED === 'true';

  return isApiMockingEnabled;
};

export default function applyMockAdapter(axiosInstance: AxiosInstance) {
  const mock = new MockAdapter(axiosInstance, mockAdapterConfig);

  const id = '[\\w-]+';
  const baseUri = `${LPSLOGIN_API_BASE_URL}/setting`;

  const testBlankEnvId = '38eed60d-d932-4dbc-afc5-636003004f64';
  const listSettingRegex = new RegExp(`(${baseUri}/)(${id})(?:\\?.+)?/?$`, 'g');
  const listSettingBlankRegex = new RegExp(
    `(${baseUri}/)(${testBlankEnvId})(?:\\?.+)?/?$`,
    'g',
  );
  const listSettingSearchRegex = new RegExp(
    `(${baseUri}/)(${id})(\\?keyword=\\w+)(?:.+)?$`,
    'g',
  );

  const getSettingRegex = new RegExp(`(${baseUri}/)(${id}/)(${id})/?$`, 'g');
  const postSettingRegex = new RegExp(`(${baseUri}/)(${id})/?$`, 'g');
  const patchSettingRegex = new RegExp(`(${baseUri}/)(${id}/)(${id})/?$`, 'g');
  const deleteSettingRegex = new RegExp(`(${baseUri}/)(${id}/)(${id})/?$`, 'g');

  mock
    .onGet(listSettingBlankRegex)
    .reply(() => [200, ListSettingBlankMockResponse]);
  mock
    .onGet(listSettingSearchRegex)
    .reply(() => [200, ListSettingBlankMockResponse]);
  mock.onGet(listSettingRegex).reply(() => [200, ListSettingMockResponse]);

  mock.onGet(getSettingRegex).reply(() => [200, GetSettingMockResponse]);
  mock.onPost(postSettingRegex).reply(() => [201, CreateSettingMockResponse]);
  mock.onPatch(patchSettingRegex).reply(() => [200, GetSettingMockResponse]);
  mock.onDelete(deleteSettingRegex).reply(200);
}
