import { WarningIcon } from '@chakra-ui/icons';
import { Spacer, Text, VStack } from '@chakra-ui/react';

import Button from './button';
import ContentBox from './content-box';

interface Props {
  reloadFn: () => void;
}

export default function ApiErrorComponent(props: Props) {
  const { reloadFn } = props;

  return (
    <ContentBox>
      <VStack spacing={2}>
        <WarningIcon color="#656666" h={8} w={8} />
        <Text fontSize="lg" fontWeight="bold">
          データの取得に失敗しました
        </Text>
        <Text fontSize="md">
          申し訳ございません、データの取得に失敗しました。画面を更新し、再度お試しください。
        </Text>
        <Spacer h={4} />
        <Button
          onClick={() => {
            reloadFn();
          }}
        >
          更新する
        </Button>
      </VStack>
    </ContentBox>
  );
}
