import { Box, Progress } from '@chakra-ui/react';
import { WebAuth } from 'auth0-js';
import { AxiosError } from 'axios';
import { Property as CSSProperty } from 'csstype';
import { useEffect, useState } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { FOOTER_HEIGHT, NAVBAR_HEIGHT } from '@/constants';
import useGetRequest from '@/hooks/useGetRequest';
import { OperationTypeState, ResourcesState } from '@/recoil';
import { GetResourcesSchema } from '@/schemas/GetResources.schema';
import { areAllExternalIntegrationsFalse } from '@/utils/contractInfoValidation';

import useAlert from './alert';
import Content from './content';
import ContentBox from './content-box';
import { logout } from './services/auth';
import Sidebar, { SIDEBAR_WIDTH } from './sidebar';

interface Props {
  children: React.ReactNode;
  isContentLoading?: boolean;
  authSession?: string;
  authService?: WebAuth;
  overflowY?: CSSProperty.OverflowY;
  isErrorPage?: boolean;
}

const endpoint = '/resources';

export default function Main({
  children,
  isContentLoading,
  authSession = '',
  authService,
  overflowY,
  isErrorPage,
}: Props) {
  const operationType = useRecoilValue(OperationTypeState);
  const setResources = useSetRecoilState(ResourcesState);

  const [initialPageLoad, setInitialPageLoad] = useState(true);
  const [resourceQuery, setResourceQuery] = useState('');
  const [isResourcesError, setResourcesError] = useState(false);
  const [noResourcesFound, setNoResourcesFound] = useState(false);

  const { addToast } = useAlert();

  // Handle sidebar items
  const {
    data,
    mutate: reloadResourcesApi,
    isLoading: isResourcesLoading,
    isError: apiError,
  } = useGetRequest(
    'env',
    authSession ? `${endpoint}?search=${resourceQuery}` : '',
    GetResourcesSchema,
    authSession,
  );

  useEffect(() => {
    setResources(data);

    if (data && areAllExternalIntegrationsFalse(data) && initialPageLoad) {
      window.location.href = '/404';
    }

    if (data) {
      setInitialPageLoad(false);
    }
  }, [data]);

  useEffect(() => {
    addToast({ type: 'onload' });
  }, []);

  // Error Handling
  useEffect(() => {
    if (apiError) {
      if (apiError instanceof AxiosError || (apiError as any).response) {
        const { status } = (apiError as any).response;

        if (status === 401) {
          if (authService) logout(authService!);
        } else if (status === 403) {
          window.location.href = '/404';
        } else if (status === 404 && resourceQuery === '') {
          window.location.href = '/404';
        } else if (status === 404 && resourceQuery !== '') {
          // Show "No match information" under searchbox
          setNoResourcesFound(true);
          setResourcesError(false);
        } else {
          setNoResourcesFound(false);
          setResourcesError(true);
        }
      }
    } else {
      setNoResourcesFound(false);
      setResourcesError(false);
    }
  }, [apiError]);

  return (
    <Box
      pt={`${NAVBAR_HEIGHT}px`}
      display="flex"
      width="100%"
      maxWidth="100%"
      style={{
        height: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      }}
    >
      {!isErrorPage && (
        <Sidebar
          isLoading={isResourcesLoading}
          isError={isResourcesError}
          noResourcesFound={noResourcesFound}
          reloadApi={reloadResourcesApi}
          setResourceQuery={setResourceQuery}
        />
      )}
      <Box
        py={isErrorPage ? undefined : isContentLoading ? undefined : '24px'}
        px={isErrorPage ? undefined : isContentLoading ? undefined : '32px'}
        bg={
          operationType === 'development'
            ? 'theme.main.development'
            : 'theme.main.production'
        }
        role="main"
        w="100%"
        overflowY={overflowY}
        style={{
          maxWidth: isErrorPage ? '100%' : `calc(100% - ${SIDEBAR_WIDTH}px)`,
        }}
      >
        {isContentLoading ? (
          <Progress size="xs" isIndeterminate />
        ) : initialPageLoad && isResourcesError ? (
          <Content>
            <ContentBox flexGrow={1} />
          </Content>
        ) : (
          children
        )}
      </Box>
    </Box>
  );
}
