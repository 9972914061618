import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Alert as ChakraAlert,
  CloseButton,
  CreateToastFnReturn,
  useToast,
} from '@chakra-ui/react';

export interface AlertProps {
  type?: 'success' | 'error' | 'onload';
  title?: string;
  message?: string;
}

interface AlertComponentProps extends AlertProps {
  toast: CreateToastFnReturn;
}

const ALERT_WIDTH = '745px';

// If the alert is of type "success", it should disappear in 3 seconds
const ALERT_DURATION_MS = 3000;

// Function to delay opening toasts after closing them
// eslint-disable-next-line no-promise-executor-return
const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

const AlertComponent = ({
  type = 'success',
  title,
  message,
  toast,
}: AlertComponentProps) => {
  const alertType = type === 'onload' ? 'success' : type;

  return (
    <ChakraAlert
      status={alertType}
      minWidth={type === 'onload' ? undefined : ALERT_WIDTH}
      display={type === 'onload' ? 'none' : undefined}
      px="12px"
      alignItems="flex-start"
      borderRadius="6px"
      borderWidth="1px"
      zIndex="5400"
      borderColor={type === 'success' ? '#51b883' : '#e85353'}
    >
      <AlertIcon />
      <Box
        flexGrow={1}
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        {title && (
          <AlertTitle fontSize="md" fontWeight="medium" display="inline-block">
            {title}
          </AlertTitle>
        )}
        <AlertDescription
          fontSize="sm"
          lineHeight="short"
          display="inline-block"
        >
          {message}
        </AlertDescription>
      </Box>
      {type === 'error' && (
        <CloseButton
          alignSelf="flex-start"
          my="2px"
          width="20px"
          height="20px"
          onClick={() => toast.closeAll()}
        />
      )}
    </ChakraAlert>
  );
};

export default () => {
  const toast = useToast();

  const addToast = ({ type = 'success', title, message }: AlertProps) => {
    toast.closeAll();

    // Delay opening toasts to prevent flicker after closing them
    sleep(250).then(() => {
      toast({
        id: type === 'onload' ? 'alert-toast-onload' : 'alert-toast',
        duration:
          type === 'onload'
            ? 100
            : type === 'success'
              ? ALERT_DURATION_MS
              : null,
        position: 'top',
        isClosable: true,
        containerStyle: {
          display: 'flex',
          justifyContent: 'center',
          marginTop: '35px',
          maxWidth: ALERT_WIDTH,
        },
        render: () => (
          <AlertComponent
            type={type}
            title={title}
            message={message}
            toast={toast}
          />
        ),
      });
    });

    return null;
  };

  return { addToast };
};
