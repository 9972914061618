import Cookies from 'js-cookie';
import { useCallback, useEffect, useState } from 'react';

import { AUTH0_LOCAL_TOKEN, COOKIE_DOMAIN, IS_LOCAL } from '@/constants';
import { getData } from '@/lib/environment-api';
import { urlSearchParamsToRecord } from '@/utils/urlParamConverter';

import {
  AUTH0_COOKIE_LOOPASS_UID_NAME,
  AUTH0_COOKIE_TOKEN_NAME,
  AUTH0_NONCE,
  authService,
  handleCheckSession,
  handleParseUser,
  login,
} from '@/pages/common/services/auth';

import {
  GetUserResponse,
  GetUserSchema,
  UserTypes,
} from '@/schemas/GetUser.schema';

export default () => {
  const sessionToken = IS_LOCAL ? AUTH0_LOCAL_TOKEN : Cookies.get('appSession');
  const [isLoading, setLoading] = useState(true);
  const [isInitialState, setIsInitialState] = useState(true);
  const [userType, setUserType] = useState<null | UserTypes>(null);
  const [email, setEmail] = useState<string>('');

  // Any Auth0 functionality is skipped locally
  // and only tested in non-local environments
  // eslint-disable-next-line no-underscore-dangle
  const _authService = IS_LOCAL ? undefined : authService();

  const getReturnAuth0Url = useCallback((state: string | null) => {
    const nonce = sessionStorage.getItem(AUTH0_NONCE);
    if (!nonce) {
      return null;
    }
    const nonceData = JSON.parse(nonce) as {
      [key: string]: {
        returnUrl: string;
        searchParams: Record<string, string>;
      };
    };
    if (!state || !nonceData[state]) {
      return null;
    }
    const { returnUrl, searchParams } = nonceData[state];
    let finalUrl = returnUrl;
    if (Object.keys(searchParams).length > 0) {
      finalUrl += '?';

      // eslint-disable-next-line no-restricted-syntax
      for (const key in searchParams) {
        if (key !== 'returnUrl') {
          finalUrl += `${key}=${searchParams[key]}&`;
        }
      }
    }
    return finalUrl;
  }, []);

  const getUserInfo = async () => {
    try {
      const token = IS_LOCAL
        ? AUTH0_LOCAL_TOKEN
        : await handleCheckSession(_authService);

      Cookies.set(AUTH0_COOKIE_TOKEN_NAME, token);

      const user = (await getData(
        '/me',
        GetUserSchema,
        token,
      )) as GetUserResponse;

      setEmail(user.email);
      setUserType(user.user_type);
      setLoading(false);
    } catch (e) {
      Cookies.remove(AUTH0_COOKIE_TOKEN_NAME);

      const routePath = window.location.pathname;
      const routeParams = new URLSearchParams(window.location.search) || {};

      login(_authService, routePath, urlSearchParamsToRecord(routeParams));
    }
  };

  useEffect(() => {
    const isCallbackFromLogin =
      window.location.search === '?authorized=true' &&
      window.location.hash.includes('access_token');

    const handleSetCookies = async () => {
      const {
        accessToken,
        idTokenPayload,
        state = null,
      } = await handleParseUser(_authService);

      const returnUrl = getReturnAuth0Url(state);

      Cookies.set(AUTH0_COOKIE_TOKEN_NAME, accessToken || '');
      Cookies.set(AUTH0_COOKIE_LOOPASS_UID_NAME, idTokenPayload.sub, {
        domain: COOKIE_DOMAIN,
        secure: true,
      });

      window.location.href = returnUrl || '/';
    };

    if (isCallbackFromLogin) {
      handleSetCookies();
    } else {
      getUserInfo();
    }

    setIsInitialState(false);
  }, []);

  // check the auth status if user changes tab
  useEffect(() => {
    const checkAuthStatus = async () => {
      await getUserInfo();
    };

    document.addEventListener('visibilitychange', checkAuthStatus);

    return () =>
      document.removeEventListener('visibilitychange', checkAuthStatus);
  }, []);

  // checks the token every route path update
  useEffect(() => {
    if (!isInitialState) {
      if (!sessionToken) {
        const routePath = window.location.pathname;
        const routeParams = new URLSearchParams(window.location.search) || {};
        login(_authService, routePath, urlSearchParamsToRecord(routeParams));
      }
    }
  }, [typeof window !== 'undefined' ? window.location.pathname : '']);

  return {
    isLoading,
    isInitialState,
    userType,
    email,
    authService: _authService,
    sessionToken,
  };
};
