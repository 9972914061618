import axios from 'axios';
import { ZodType } from 'zod';

import { ENVIRONMENT_API_BASE_URL } from '../constants';

export const getData = async (
  endpoint: string,
  schema: ZodType<unknown>,
  bearerToken: string,
) => {
  try {
    const response = await axios.get(`${endpoint}`, {
      baseURL: ENVIRONMENT_API_BASE_URL,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    const validatedData = schema.parse(response.data);

    return validatedData;
  } catch (error) {
    console.error('API request or validation failed:', error);

    throw error;
  }
};
