'use client';

/* eslint-disable no-unused-expressions */
import { Dispatch, SetStateAction, useEffect, useRef } from 'react';

import type {
  ButtonProps,
  TableBodyProps,
  TableCellProps,
  TableColumnHeaderProps,
  TableContainerProps,
  TableHeadProps,
  TableProps,
  TableRowProps,
} from '@chakra-ui/react';

import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';

import {
  Button,
  TableContainer as ChakraTableContainer,
  HStack,
  Image,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';

interface CustomTableProps extends TableProps {
  tableContainerProps?: TableContainerProps;
}

interface TableProgressProps {
  colSpan: number;
}

interface TablePaginationProps {
  currentPage: number;
  totalPages: number;
  totalRecords: number;
  onPageChange: Dispatch<SetStateAction<number>>;
}

export const TABLE_PAGINATION_HEIGHT = 48;

export const TableContainer = (props: CustomTableProps) => {
  const { children, tableContainerProps, ...otherProps } = props;
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const tableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const tableContainer = tableContainerRef.current;
    const tableRefCurrent = tableRef.current;

    const handleScroll = () => {
      if (tableContainer) {
        const isRightMost =
          tableContainer.scrollLeft + tableContainer.clientWidth >=
          tableContainer.scrollWidth - 5;

        const isLeftMost = tableContainer.scrollLeft > 0;

        if (isRightMost) {
          tableContainer.classList.add('hasScrollRight');
        } else {
          tableContainer.classList.remove('hasScrollRight');
        }

        if (isLeftMost) {
          tableContainer.classList.add('hasScrollLeft');
        } else {
          tableContainer?.classList.remove('hasScrollLeft');
        }
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      handleScroll(); // Check scroll position on resize as well
    });

    tableContainer?.addEventListener('scroll', handleScroll);
    tableRefCurrent && resizeObserver.observe(tableRefCurrent);

    return () => {
      tableContainer?.removeEventListener('scroll', handleScroll);
      tableRefCurrent && resizeObserver.unobserve(tableRefCurrent);
    };
  }, []);

  return (
    <ChakraTableContainer
      {...tableContainerProps}
      ref={tableContainerRef}
      style={{
        maxWidth: '100%',
        height: '100%',
        overflowY: 'auto',
        minHeight: '170px',
      }}
    >
      <Table variant="simple" role="table" {...otherProps} ref={tableRef}>
        {children}
      </Table>
    </ChakraTableContainer>
  );
};

export const TableHeader = (props: TableHeadProps) => {
  const { children, ...otherProps } = props;

  return (
    <Thead
      bg="theme.table.heading"
      fontWeight="medium"
      color="theme.text.secondary"
      {...otherProps}
    >
      {children}
    </Thead>
  );
};

export const TableBody = (props: TableBodyProps) => {
  const { children, ...otherProps } = props;

  return (
    <Tbody fontSize="md" color="theme.text.primary" {...otherProps}>
      {children}
    </Tbody>
  );
};

export const TableRow = (props: TableRowProps) => {
  const { children, ...otherProps } = props;

  return <Tr {...otherProps}>{children}</Tr>;
};

export const TableProgress = (props: TableProgressProps) => {
  const { colSpan } = props;

  return (
    <Tr>
      <Td colSpan={colSpan} padding={0}>
        <Progress size="xs" isIndeterminate />
      </Td>
    </Tr>
  );
};

export const TableHeading = (props: TableColumnHeaderProps) => {
  const { children, ...otherProps } = props;

  return (
    <Th
      px="24px"
      py="14px"
      fontSize="md"
      letterSpacing={0}
      lineHeight="short"
      paddingRight={0}
      backgroundColor="#f4fbfc"
      position="sticky"
      color="#656666"
      top="0"
      {...otherProps}
    >
      {children}
    </Th>
  );
};

export const TableColumn = (props: TableCellProps) => {
  const { children, ...otherProps } = props;

  return (
    <Td px="24px" py="12px" {...otherProps}>
      {children}
    </Td>
  );
};

const PaginationButton = (props: ButtonProps) => (
  <Button
    fontSize="24px"
    fontWeight="bold"
    minWidth="32px"
    height="32px"
    backgroundColor="#fff"
    px={0}
    _hover={{
      backgroundColor: '#f9f9f9',
    }}
    {...props}
  />
);

export const TablePagination = (props: TablePaginationProps) => {
  const { currentPage, totalPages, totalRecords, onPageChange } = props;

  const pages = Array(totalPages)
    .fill(0)
    .map((_, index) => index + 1);

  return (
    <HStack justify="flex-end" mt="16px" spacing={2} height="32px">
      <PaginationButton
        aria-label="Prev button"
        isDisabled={currentPage === 1}
        color={currentPage === 1 ? '#dedfdf' : '#656666'}
        onClick={() => {
          onPageChange((page) => page - 1);
        }}
      >
        <ChevronLeftIcon />
      </PaginationButton>
      {pages.map((number) => {
        // show first, last and two pages around current page.
        if (
          number === 1 ||
          number === totalPages ||
          (number >= currentPage - 2 && number <= currentPage + 2)
        ) {
          const isCurrentPage = currentPage === number;

          return (
            <PaginationButton
              key={number}
              fontSize="sm"
              fontWeight="medium"
              borderColor={isCurrentPage ? 'brand.500' : undefined}
              color={isCurrentPage ? 'brand.500' : 'text.primary'}
              _hover={{
                backgroundColor: isCurrentPage ? '#f4fbfc' : '#f9f9f9',
              }}
              variant={isCurrentPage ? 'outline' : 'ghost'}
              onClick={() => {
                onPageChange(() => number);
              }}
            >
              {number}
            </PaginationButton>
          );
        }
        if (number === currentPage - 3 || number === currentPage + 3) {
          return (
            <Text
              key={number}
              width="32px"
              height="32px"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Image
                src="/icons/pagination.svg"
                alt="edit"
                width="16px"
                height="4px"
                style={{ margin: '0 auto' }}
              />
            </Text>
          );
        }
        return null;
      })}
      <PaginationButton
        aria-label="Next button"
        isDisabled={currentPage === totalPages}
        color={currentPage === totalPages ? '#dedfdf' : '#656666'}
        onClick={() => {
          onPageChange((page) => page + 1);
        }}
      >
        <ChevronRightIcon />
      </PaginationButton>
      <Text variant="secondary" fontWeight="medium" fontSize="sm">
        {`合計${totalRecords}件`}
      </Text>
    </HStack>
  );
};

export default {
  Header: TableHeader,
  Body: TableBody,
  Row: TableRow,
  Heading: TableHeading,
  Column: TableColumn,
  Progress: TableProgress,
  Pagination: TablePagination,
};
