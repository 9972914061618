import {
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Alert as ChakraAlert,
  CloseButton,
} from '@chakra-ui/react';

export interface AlertProps {
  type?: 'success' | 'error';
  title?: string;
  message?: string;
  onClose: () => void;
}

const ALERT_WIDTH = '745px';

const AlertComponent = ({
  type = 'error',
  title = 'エラー発生',
  message = 'エラーが発生しました。ご確認の上、もう一度お試しください。',
  onClose,
}: AlertProps) => (
  <ChakraAlert
    status={type}
    width={ALERT_WIDTH}
    px="12px"
    alignItems="flex-start"
    borderRadius="6px"
    borderWidth="1px"
    borderColor={type === 'success' ? '#51b883' : '#e85353'}
  >
    <AlertIcon />
    <Box
      flexGrow={1}
      display="flex"
      flexDirection="column"
      height="100%"
      justifyContent="center"
    >
      {title && (
        <AlertTitle fontSize="md" fontWeight="medium" display="inline-block">
          {title}
        </AlertTitle>
      )}
      <AlertDescription fontSize="sm" lineHeight="short" display="inline-block">
        {message}
      </AlertDescription>
    </Box>
    {type === 'error' && (
      <CloseButton
        alignSelf="flex-start"
        my="2px"
        width="20px"
        height="20px"
        onClick={onClose}
      />
    )}
  </ChakraAlert>
);

export default AlertComponent;
