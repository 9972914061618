'use client';

import { WarningIcon } from '@chakra-ui/icons';
import { debounce } from 'lodash-es';
import { useRouter } from 'next/router';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { KeyedMutator } from 'swr';

import {
  ChangeEvent,
  Dispatch,
  Fragment,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';

import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Progress,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';

import { OperationTypes } from '@/recoil/OperationTypeState';
import { Environments, Providers } from '@/schemas/GetResources.schema';
import { findEnvironmentType } from '@/utils/findEnvironmentType';

import {
  FOOTER_HEIGHT,
  NAVBAR_HEIGHT,
  SIDEBAR_SEARCH_DEBOUNCE_MS,
} from '@/constants';

import {
  EnvironmentIdState,
  OperationTypeState,
  ResourcesState,
} from '@/recoil';

import Button from '../button';
import { SearchIcon } from '../icons';
import ItemCompanyName from './components/ItemCompanyName';
import ItemEnvironmentName from './components/ItemEnvironmentName';
import ItemProviderName from './components/ItemProviderName';

type Props = {
  isLoading: boolean;
  isError: boolean;
  noResourcesFound: boolean;
  reloadApi: KeyedMutator<any>;
  setResourceQuery: Dispatch<SetStateAction<string>>;
};

export const SIDEBAR_WIDTH = 221;
const SEARCH_HEIGHT = 33.75;

export default function Sidebar(props: Props) {
  const { isLoading, isError, noResourcesFound, reloadApi, setResourceQuery } =
    props;

  const router = useRouter();
  const envid = router.query.envid as string;

  const setOperationType = useSetRecoilState(OperationTypeState);
  const setEnvironmentId = useSetRecoilState(EnvironmentIdState);
  const data = useRecoilValue(ResourcesState);

  const [search, setSearch] = useState<string>('');
  const [selected, setSelected] = useState<null | string>(null);

  const handleDebounceFn = (value: string) => {
    setResourceQuery(value);
  };

  const debouncedSearch = useCallback(
    debounce(handleDebounceFn, SIDEBAR_SEARCH_DEBOUNCE_MS),
    [],
  );

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
    debouncedSearch(event.target.value);
  };

  const handleChangeSelected = (id: string) => {
    router.push(`/${id}`);
  };

  const showProvider = (environments: Environments): boolean => {
    return environments.some(
      (environment) => environment.contract_info.loopass_line_login,
    );
  };

  const showItem = (providers: Providers): boolean => {
    return providers.some((provider) =>
      showProvider(provider.environments ?? []),
    );
  };

  const findOperationType = (): OperationTypes => {
    const selectedEnvironment = findEnvironmentType(envid, data!);

    return selectedEnvironment?.type ?? null;
  };

  useEffect(() => {
    if (envid) {
      setSelected(envid);
      setEnvironmentId(envid);
    } else {
      setEnvironmentId('');
    }
    setOperationType(findOperationType());
  }, [envid]);

  useEffect(() => {
    if (data) {
      setOperationType(findOperationType());
    }
  }, [data]);

  return (
    <Stack
      px={1}
      pt={4}
      spacing={0}
      bg="theme.sidebar.background"
      minWidth={`${SIDEBAR_WIDTH}px`}
      width={`${SIDEBAR_WIDTH}px`}
      overflowY="auto"
      role="menu"
      style={{
        height: `calc(100vh - ${NAVBAR_HEIGHT}px - ${FOOTER_HEIGHT}px)`,
      }}
    >
      <Box paddingBottom="16px">
        <InputGroup>
          <InputRightElement pointerEvents="none" height={`${SEARCH_HEIGHT}px`}>
            <SearchIcon width="18px" height="18px" color="#a1a1a1" />
          </InputRightElement>
          <Input
            value={search}
            onChange={handleChangeSearch}
            placeholder="会社名で検索"
            h={`${SEARCH_HEIGHT}px`}
            border="1px solid #eceded"
            borderRadius="4px"
            variant="unstyled"
            background="#fff"
            paddingLeft="16px"
            paddingRight="43px"
            fontSize="15px"
            lineHeight="27px"
            role="form"
            color="theme.text.primary"
            _placeholder={{
              color: '#B6B7B7',
            }}
          />
        </InputGroup>
      </Box>

      {noResourcesFound && (
        <Text
          fontSize="12px"
          textAlign="center"
          lineHeight="17px"
          color="white"
        >
          一致する情報が見つかりません
        </Text>
      )}

      {isLoading && <Progress size="xs" isIndeterminate />}

      {isError && (
        <VStack spacing={0} mt={-2}>
          <WarningIcon color="white" width={7} height={7} />
          <Text fontSize="sm" fontWeight="bold" color="white" py={1} mb={2}>
            読み込みに失敗しました
          </Text>
          <Button
            fontWeight="bold"
            fontSize="sm"
            onClick={() => {
              reloadApi();
            }}
          >
            更新する
          </Button>
        </VStack>
      )}

      {data?.items.map(
        (item) =>
          item.providers &&
          showItem(item.providers) && (
            <Box p={2} key={item.id}>
              <ItemCompanyName name={item.name} />
              {item.providers.map(
                (provider) =>
                  provider.environments &&
                  showProvider(provider.environments) && (
                    <Fragment key={provider.id}>
                      <ItemProviderName name={provider.name} />
                      {provider.environments.map(
                        (environment) =>
                          environment.contract_info.loopass_line_login && (
                            <ItemEnvironmentName
                              key={environment.id}
                              name={environment.line_account_name}
                              isSelected={selected === environment.id}
                              onClick={() => {
                                handleChangeSelected(environment.id);
                              }}
                            />
                          ),
                      )}
                    </Fragment>
                  ),
              )}
            </Box>
          ),
      )}
    </Stack>
  );
}
