import axios from 'axios';

import applyMockAdapter, { isMockingEnabled } from '@/mocks';

const axiosInstance = axios.create();

if (isMockingEnabled()) {
  applyMockAdapter(axiosInstance);
}

export default axiosInstance;
