interface Record {
  id: string;
  env_id: string;

  consent_url: string;
  cancel_url: string;
  block_url: string;
  webhook_urls?: Array<string>;

  is_add_friend: boolean;
  is_status: boolean;
  note: string;

  created_at: string;
  updated_at: string;
}

interface Records extends Array<Record> {}

export interface ListSettingResponse {
  records: Records;
  page: number;
  total: number;
}

export interface GetSettingResponse extends Record {}

export const ListSettingMockResponse: ListSettingResponse = {
  records: [
    {
      id: 'b27a68f0-44d1-4a0d-bfab-836524fb6c35',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: '',
      is_add_friend: false,
      is_status: false,
      note: '',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '7b64b1a6-5de0-4c80-820e-b457761ee344',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: 'https://google.com',
      is_add_friend: true,
      is_status: false,
      note: 'sample note',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '09c37ecd-0718-404b-a229-6899be1f33fd',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: 'https://google.com',
      is_add_friend: true,
      is_status: false,
      note: 'sample note',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: '0c622805-8f12-440e-8b0b-cd5103223153',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: 'https://google.com',
      is_add_friend: true,
      is_status: false,
      note: 'sample note',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: 'df33d37b-de3e-4bcf-adbd-22ee8cb838c1',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: 'https://google.com',
      is_add_friend: true,
      is_status: false,
      note: 'sample note',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
    {
      id: 'c6828165-54e0-400e-ad29-dc34b4f23b44',
      env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
      consent_url: 'https://google.com',
      cancel_url: 'https://google.com',
      block_url: 'https://google.com',
      is_add_friend: true,
      is_status: false,
      note: 'sample note',
      created_at: '2023-10-23T16:29:41.734296+0000',
      updated_at: '2023-10-23T16:29:41.734296+0000',
    },
  ],
  page: 1,
  total: 202,
};

export const ListSettingBlankMockResponse: ListSettingResponse = {
  records: [],
  page: 1,
  total: 0,
};

export const GetSettingMockResponse: GetSettingResponse = {
  id: 'c6828165-54e0-400e-ad29-dc34b4f23b44',
  env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
  consent_url: 'https://consent.url',
  cancel_url: 'https://cancel.url',
  block_url: 'https://block.url',
  webhook_urls: [
    'https://webhook.url/1',
    'https://webhook.url/2',
    'https://webhook.url/3',
  ],
  is_add_friend: true,
  is_status: true,
  note: 'this is a note',
  created_at: '2023-10-23T16:29:41.734296+0000',
  updated_at: '2023-10-23T16:29:41.734296+0000',
};

export const CreateSettingMockResponse: Partial<GetSettingResponse> = {
  id: '7a30d735-2921-4919-a1d4-13d1d1a3380d',
  env_id: '6ed426f4-392e-4a04-8ae3-ef767add306c',
  consent_url: 'https://consent.url',
  cancel_url: 'https://cancel.url',
  block_url: 'https://block.url',
  is_add_friend: true,
  is_status: true,
  note: 'this is a note',
};
