export const DEPLOYMENT_ENV = process.env.NEXT_PUBLIC_DEPLOYMENT_ENV;
export const IS_LOCAL = DEPLOYMENT_ENV === 'local';

const IS_MOCKING = process.env.NEXT_PUBLIC_API_MOCKING_ENABLED === 'true';
const BASE_DOMAIN = IS_LOCAL ? 'localhost:3000' : 'loopass.net';

const subsystem = 'lpslogin';
let prefix: string = '';

if (
  DEPLOYMENT_ENV === 'dev' ||
  DEPLOYMENT_ENV === 'stg' ||
  DEPLOYMENT_ENV === 'test'
) {
  prefix = `${DEPLOYMENT_ENV}.`;
}

export const BASE_URL = IS_LOCAL
  ? BASE_DOMAIN
  : `https://${subsystem}.${prefix}${BASE_DOMAIN}`;

export const PORTAL_URL = IS_LOCAL
  ? 'dev.loopass.net'
  : `${prefix}${BASE_DOMAIN}`;

export const LINE_LOGIN_URL = IS_LOCAL
  ? `http://${BASE_DOMAIN}`
  : `https://r.${subsystem}.${prefix}${BASE_DOMAIN}`;

export const ENVIRONMENT_API_BASE_URL =
  IS_MOCKING && IS_LOCAL
    ? 'https://mock-ema-api.tact.dev.loopass.net'
    : `https://api.manage.${prefix}${BASE_DOMAIN}`;

export const LPSLOGIN_API_BASE_URL =
  IS_MOCKING && IS_LOCAL
    ? `${BASE_DOMAIN}/lpslogin-api`
    : `https://api.${subsystem}.${prefix}${BASE_DOMAIN}`;

export const LPSLOGIN_API_KEY = process.env.NEXT_PUBLIC_LPSLOGIN_API_KEY || '';

export const MAX_LIST_RECORD_PER_PAGE = 20;

export const NAVBAR_HEIGHT = 69.75;
export const FOOTER_HEIGHT = 33;
export const SIDEBAR_SEARCH_DEBOUNCE_MS = 1000;

// Auth0 Constants
export const AUTH0_DOMAIN = process.env.NEXT_PUBLIC_AUTH0_DOMAIN || '';
export const AUTH0_CLIENT_ID = process.env.NEXT_PUBLIC_AUTH0_CLIENT_ID || '';
export const AUTH0_AUDIENCE = process.env.NEXT_PUBLIC_AUTH0_AUDIENCE || '';
export const AUTH0_LOCAL_TOKEN =
  process.env.NEXT_PUBLIC_AUTH0_LOCAL_TOKEN || '';

export const COOKIE_DOMAIN = process.env.NEXT_PUBLIC_COOKIE_DOMAIN;

export const NOT_ALLOWED_URLS = [
  'r.lpslogin.dev.loopass.net',
  'r.lpslogin.test.loopass.net',
  'r.lpslogin.stg.loopass.net',
  'r.lpslogin.loopass.net',
];

export const WEBHOOK_URL_LIMIT = 5;
