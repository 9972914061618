import { FOOTER_HEIGHT, NAVBAR_HEIGHT } from '@/constants';
import { Box } from '@chakra-ui/react';

export default function App({ children }: { children: React.ReactNode }) {
  return (
    <Box
      pt={`${NAVBAR_HEIGHT}px`}
      display="flex"
      width="100%"
      maxWidth="100%"
      style={{
        height: `calc(100vh - ${FOOTER_HEIGHT}px)`,
      }}
    >
      <Box
        role="main"
        w="100%"
        style={{
          maxWidth: '100%',
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
