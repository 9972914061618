import { object, string } from 'zod';

export enum USER_TYPES {
  MG_USER = 'mg_user',
  USER = 'user',
}

export type UserTypes = `${USER_TYPES}`;

export interface GetUserResponse {
  user_type: UserTypes;
  email: string;
}

export const GetUserSchema = object({
  user_type: string(),
  email: string(),
});
