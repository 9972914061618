import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const baseStyle = definePartsStyle({
  // this will style the MenuList component
  list: {
    mt: '18px',
    padding: '8px 4px',
    borderRadius: '4px',
    border: 'none',
    width: '203px',
    minWidth: '203px',
    zIndex: 5350,
  },
  // this will style the MenuItem components
  item: {
    color: '#232525',
    fontSize: '13px',
    height: '26px',
    _hover: {
      bg: '#ddf2f4',
      textDecor: 'none',
    },
    _focus: {
      bg: '#fff',
    },
  },
});

const menuConfig = defineMultiStyleConfig({ baseStyle });

export default menuConfig;
