'use client';

import { QuestionOutlineIcon } from '@chakra-ui/icons';
import { forwardRef } from 'react';

import {
  Input as ChakraInput,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputProps,
  InputRightElement,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';

interface Props extends InputProps {
  label?: string;
  helperText?: string;
  errorMessage?: string;
  isError?: boolean;
  isLoading?: boolean;
  tooltip?: string;
}

const Input = forwardRef((props: Props, ref) => {
  const {
    label,
    helperText,
    errorMessage,
    isError,
    isRequired,
    isDisabled,
    isLoading,
    tooltip,
    ...otherProps
  } = props;

  return (
    <FormControl
      isInvalid={isError}
      isRequired={isRequired}
      isDisabled={isDisabled}
    >
      {label && (
        <FormLabel
          fontSize="md"
          display={tooltip ? 'inline-block' : 'block'}
          marginRight={tooltip ? 1 : undefined}
        >
          {label}
        </FormLabel>
      )}

      {tooltip && (
        <Tooltip
          hasArrow
          label={tooltip}
          fontSize="sm"
          bg="#0c0c0c"
          placement="top"
        >
          <QuestionOutlineIcon />
        </Tooltip>
      )}

      <InputGroup size="md">
        <ChakraInput
          ref={ref}
          borderRadius="4px"
          borderWidth="1px"
          borderColor="#eceded"
          fontSize="md"
          minHeight="43px"
          px="12px"
          isRequired={isRequired}
          isDisabled={isDisabled}
          _placeholder={{ color: '#bebfbf' }}
          role="form"
          focusBorderColor="#b5e3eb"
          errorBorderColor="#e85353"
          {...otherProps}
        />
        {isLoading && (
          <InputRightElement>
            <Spinner color="brand.500" />
          </InputRightElement>
        )}
      </InputGroup>

      {!isError ? (
        <FormHelperText fontSize="sm" mt={0}>
          {helperText}
        </FormHelperText>
      ) : (
        <FormErrorMessage fontSize="sm" mt={0} color="#d34141">
          {errorMessage}
        </FormErrorMessage>
      )}
    </FormControl>
  );
});

export default Input;
