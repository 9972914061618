import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { WebAuth } from 'auth0-js';
import { useState } from 'react';

import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from '@chakra-ui/react';

import { NAVBAR_HEIGHT, PORTAL_URL } from '@/constants';
import { USER_TYPES } from '@/schemas/GetUser.schema';

import { logout } from './services/auth';

import styles from './theme/menu.module.css';

type Props = {
  auth: boolean;
  AuthService?: WebAuth;
  email: string;
  userType: string;
};

const OpenNewTabIcon = () => (
  <Image
    src="/icons/new-tab.svg"
    alt=""
    width="16px"
    height="16px"
    marginLeft="auto"
    marginRight="0"
  />
);

export default function Navbar({ auth, AuthService, email, userType }: Props) {
  const [isMenuOpen, setMenuOpen] = useState(false);

  const handleClickLogout = () => {
    if (AuthService) {
      logout(AuthService);
    }
  };

  const handleNavigatePortal = () => {
    if (userType === USER_TYPES.MG_USER) {
      window.open(`https://admin.${PORTAL_URL}`, '_newtab');
    } else if (userType === USER_TYPES.USER) {
      window.open(`https://user.${PORTAL_URL}`, '_newtab');
    }
  };

  return (
    <Box
      bg="theme.navbar"
      color="white"
      px={8}
      py={3}
      role="navigation"
      zIndex="5450"
      position="fixed"
      width="100%"
    >
      <Flex
        h={`calc(${NAVBAR_HEIGHT}px - 24px)`}
        alignItems="center"
        justifyContent="flex-start"
      >
        <HStack
          spacing={8}
          width="100%"
          alignItems="center"
          justify="space-between"
        >
          <HStack spacing="7.5px">
            <Box
              cursor="pointer"
              data-testid="navbar-brand"
              onClick={() => {
                window.location.href = '/';
              }}
            >
              <Image
                src="/img/brand.svg"
                alt="Brand Logo"
                height={0}
                width={0}
                style={{ width: '158px', height: '25.3px' }}
              />
            </Box>
            <Text fontSize="15px" lineHeight="27px" color="white">
              LOOPASS LINE ログイン
            </Text>
          </HStack>
          {auth && (
            <HStack spacing="12px">
              <Text
                fontSize="15px"
                lineHeight="27px"
                color="white"
                cursor="pointer"
                onClick={handleNavigatePortal}
              >
                ポータル
              </Text>
              <Center height="12px">
                <Divider
                  borderLeft={0}
                  orientation="vertical"
                  borderWidth="1px"
                  borderColor="white"
                  height="12px"
                />
              </Center>
              <HStack spacing={0}>
                <Image src="/icons/user.svg" alt="" width={13} height={13} />
                <Menu placement="bottom-end">
                  <MenuButton
                    as={Button}
                    fontSize="13.2px"
                    fontWeight="bold"
                    lineHeight="21.12px"
                    variant="link"
                    color="white"
                    marginLeft="8px"
                    _hover={{
                      textDecor: 'none',
                    }}
                    style={{
                      color: 'white',
                    }}
                    className={styles.customMenuStyle}
                    rightIcon={
                      isMenuOpen ? (
                        <ChevronUpIcon width="20px" height="20px" />
                      ) : (
                        <ChevronDownIcon width="20px" height="20px" />
                      )
                    }
                    onClick={() => {
                      setMenuOpen(() => !isMenuOpen);
                    }}
                  >
                    {email}
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      as={Link}
                      target="_blank"
                      href="https://www.loopass.net/column"
                    >
                      コラム
                      <OpenNewTabIcon />
                    </MenuItem>
                    <MenuItem
                      as={Link}
                      target="_blank"
                      href="https://www.loopass.net/"
                    >
                      サービスサイト
                      <OpenNewTabIcon />
                    </MenuItem>
                    <MenuItem
                      as={Link}
                      target="_blank"
                      href="https://example.com"
                    >
                      ヘルプページ
                      <OpenNewTabIcon />
                    </MenuItem>
                    <MenuItem onClick={handleClickLogout}>ログアウト</MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </HStack>
          )}
        </HStack>
      </Flex>
    </Box>
  );
}
