import {
  Environment,
  Environments,
  GetResourcesResponse,
} from '@/schemas/GetResources.schema';

export const findEnvironmentType = (
  environmentId: string,
  data: GetResourcesResponse,
): undefined | Environment => {
  if (data) {
    const allEnvironments: Environments = [];

    data.items.forEach((item) => {
      if (item.providers) {
        item.providers.forEach((provider) => {
          if (provider.environments) {
            provider.environments.forEach((environment) => {
              allEnvironments.push(environment);
            });
          }
        });
      }
    });

    const selectedEnvironment = allEnvironments.find(
      ({ id }) => id === environmentId,
    );

    return selectedEnvironment;
  }

  return undefined;
};
